<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  @media (max-width: 1024px) {
    .sm-spacer {
      margin-top: .5rem;
    }
  }
  .save-btn.btn-success {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #34c38f;
    }

    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import {Game, OmegaAction, StreamEvent, TriggerAttributes, TriggerCondition} from "@/enums";

const humanizeDuration = require("humanize-duration");

export default {
  props: ['server_id', 'options'],
  components: {
    VclCode,
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    Multiselect
  },
  computed: {
    ...mapGetters([
      'getServer'
    ]),
  },
  methods: {
    // Source: https://gist.github.com/jed/982883
    uuid(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,this.uuid)},
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getConfiguration: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/messenger`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.configuration = data.configuration;
          this.isSubscriber = data.is_subscriber;
          this.limit = data.limit;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },

    /* ************************** */
    /* Special features for actions */

    // https://stackoverflow.com/questions/1267283/how-can-i-pad-a-value-with-leading-zeros
    zeroFill(number, width) {
      width -= number.toString().length;
      if ( width > 0 )
      {
        return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
      }
      return number + ""; // always return a string
    },
    getMsgTimeString(msg) {
      return '';
    },
    validateMessage(msg) {
      return true;
    },
    humanizeDuration(duration) {
      return humanizeDuration(duration * 1000);
    },
    /* ************************** */
    async saveConfiguration() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let messenger = [];
        /* Integrity check */
        this.configuration.messenger.messages.forEach((msg) => {
          if(!this.validateMessage(msg)) return;
          else messenger.push(msg)
        });
        this.configuration.messenger.messages = messenger;
        /* *************** */
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          configuration: this.configuration
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/messenger`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.configuration.save.success.title'),
            text: this.$t('server.view.configuration.save.success.text'),
          });
          this.configurationChanged = null;
          await this.getConfiguration();
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    },
    /* Processing */
    async addElement() {
      this.configuration.messenger.messages.push({
        offset: 0,
        interval: 60,
        string: 'Message',
        mode: 'public',
        uuid: this.uuid()
      });
    },
    async removeElement(uuid) {
      let elements = [];
      this.configuration.messenger.messages.forEach((el) => {
        if(el.uuid !== uuid) elements.push(el);
      });
      this.configuration.messenger.messages = [];
      this.configuration.messenger.messages = elements;
    },
    /* ***** */
	  scrollHandler() {
		  if(!this.$refs.floatingContainer) return;

		  if(window.pageYOffset > 70) {
			  if(!this.$refs.floatingContainer.classList.contains('c-floating-side')) {
				  this.$refs.floatingContainer.classList.add('c-floating-side');
			  }
		  } else {
			  this.$refs.floatingContainer.classList.remove('c-floating-side');
		  }
	  }
  },
  created() {
    this.server = this.getServer(this.server_id);
  },
  mounted() {
    this.getConfiguration();

	  window.addEventListener('scroll', this.scrollHandler);
  },
	destroyed() {
		window.removeEventListener('scroll', this.scrollHandler);
	},
  watch: {
    configuration: {
      deep: true,
      handler() {
        if(!this.ready) return;
        if(this.configurationChanged === null) {
          this.configurationChanged = false;
        } else this.configurationChanged = true;
      }
    }
  },
  data() {
    return {
      Game:Game,
      OmegaAction:OmegaAction,
      ready: false,
      error: false,
      inProgress: false,
      server: null,
      isSubscriber: false,
      configurationChanged: null,
      configuration: {

      },
      limit: null,
      availableModes: [
          'public',
          'private',
      ],
      // Special configs
      special: {

      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-sm-12 d-lg-none text-center">
          <h4 class="p-0 d-inline-block mr-2 align-middle">Module</h4>
          <toggle-button v-model="configuration.messenger.status"
                         :sync="true"
                         :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                         :height="30"
                         :width="70"
                         :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                         style="font-size: 13px !important;"
                         class="m-0 mb-1"
          />
        </div>
        <div class="col-sm-12 mt-1 d-lg-none">
          <button class="btn btn-block hard-border save-btn"
                  v-on:click="saveConfiguration()"
                  :disabled="inProgress"
                  :class="{
                    'disabled': inProgress,
                    'btn-success': configurationChanged === true,
                    'btn-primary': configurationChanged === false
                  }"
          >
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.view.configuration.save.button') }}
            </template>
          </button>
          <div class="text-center small text-muted" v-if="configurationChanged === true">
            {{ $t('server.view.configuration.save.info') }}
          </div>
        </div>
        <div class="col-sm-12 mt-2 d-lg-none">
          <template v-if="!limit || configuration.messenger.messages.length < limit">
            <button class="btn btn-info btn-block hard-border mb-2" v-on:click="addElement()">
              <i class="far fa-plus" />
              {{ $t('server.view.messenger.button') }}
            </button>
          </template>
          <template v-if="limit">
            <div class="card text-center">
              <div class="card-body" v-if="configuration.messenger.messages.length >= limit">
                <h5 class="text-danger">
                  <i class="fad fa-times-hexagon text-danger"/>
                  {{$t('server.view.messenger.limits.reached')}}
                </h5>
                <span class="h5 text-danger">{{configuration.messenger.messages.length}} / {{limit}}</span>
              </div>
              <div class="card-body" v-else>
                <h5>
                  {{$t('server.view.messenger.limits.info')}}
                </h5>
                <span class="h5">{{configuration.messenger.messages.length}} / {{limit}}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="col-sm-12 mt-2 d-lg-none">
          <div class="card hard-border ">
            <div class="card-body">
              <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
              {{$t('server.view.configuration.placeholders.message')}}
              <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
                <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-sm-12">
          <div>
            <div role="tablist">
              <b-card no-body class="settings-collapse hard-border" v-for="msg in configuration.messenger.messages" :key="msg.uuid">
                <a v-b-toggle="msg.uuid" class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h4 class="m-0 d-inline-flex hide-sm">
                      <template v-if="validateMessage(msg)">
                        <template v-if="msg.uuid === 'default-branding' && !isSubscriber">
                          <span class="badge badge-danger mr-1">
                            <i class="far fa-lock"/>
                          </span>
                        </template>
                        "{{ msg.string.substring(0, 64) }}"
                        <small class="text-muted ml-1 mt-auto">
                          <i class="fal fa-repeat" />
                          {{ humanizeDuration(msg.interval) }}
                        </small>
                        <small class="text-muted ml-2 mt-auto" v-if="msg.offset > 0">
                          <i class="fal fa-hourglass" />
                          {{ humanizeDuration(msg.offset) }}
                        </small>
                      </template>
                      <template v-else>
                        <div class="badge badge-warning text-black">
                          {{ $t('server.view.messenger.unconfigured') }}
                        </div>
                      </template>
                    </h4>
                    <h5 class="m-0 d-inline-flex d-lg-none">
                      <template v-if="validateMessage(msg)">
                        <template v-if="msg.uuid === 'default-branding' && !isSubscriber">
                          <span class="badge badge-danger mr-1">
                            <i class="far fa-lock"/>
                          </span>
                        </template>
                        "{{ msg.string.substring(0, 64) }}"
                      </template>
                      <template v-else>
                        <div class="badge badge-warning text-black">
                          {{ $t('server.view.messenger.unconfigured') }}
                        </div>
                      </template>
                    </h5>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.configuration.expand') }}
                      </div>
                    </div>
                    <template v-if="msg.uuid === 'default-branding' && isSubscriber || msg.uuid !== 'default-branding'">
                      <div class="d-inline-flex float-right mr-2">
                        <div class="btn btn-sm btn-danger" v-on:click="removeElement(msg.uuid)">
                          <i class="fal fa-trash-alt" />
                          {{ $t('server.view.configuration.remove') }}
                        </div>
                      </div>
                    </template>
                    <div class="mt-2 d-lg-none">
                      <span class="text-muted mt-auto">
                        <i class="fal fa-repeat" />
                        {{ humanizeDuration(msg.interval) }}
                      </span>
                      <span class="text-muted ml-2 mt-auto" v-if="msg.offset > 0">
                        <i class="fal fa-hourglass" />
                        {{ humanizeDuration(msg.offset) }}
                      </span>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse :id="msg.uuid" accordion="messenger" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <template v-if="msg.uuid === 'default-branding' && !isSubscriber">
                        <div class="alert alert-warning text-center font-size-16">
                          <b>
                            {{ $t('server.view.messenger.branded') }}
                          </b>
                        </div>
                      </template>
                      <template v-else>
                        <h4>{{ $t('server.view.messenger.mode.label') }}</h4>
                      </template>
                      <div class="row" v-if="msg.uuid === 'default-branding' && isSubscriber || msg.uuid !== 'default-branding'">
                        <div class="col-lg-6 col-sm-12 sm-spacer">
                          <hr>
                          <div class="row">
                            <div class="col-lg-12 col-sm-12">
                              <multiselect
                                  v-model="msg.mode"
                                  :options="availableModes"
                                  :multiple="false"
                                  :close-on-select="true"
                                  :clear-on-select="false"
                                  :preserve-search="true"
                                  :placeholder="$t('server.view.messenger.mode.placeholder')"
                                  :preselect-first="false"
                                  :taggable="false"
                              >
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                                  <div>
                                    <span class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ $t(`server.view.messenger.mode.${props.option}`) }}
                                      </span>
                                    </span>
                                  </div>
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="props"
                                >
                                  <div>
                                    <div class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ $t(`server.view.messenger.mode.${props.option}`) }}
                                      </span>
                                    </div>
                                  </div>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-12 sm-spacer" style="margin-top: 2rem;">
                          <b-input
                              v-model="msg.interval"
                              :readonly="msg.uuid === 'default-branding' && !isSubscriber" size="lg"
                              :placeholder="$t('server.view.messenger.interval.placeholder')"
                              type="number"
                              class="form-control"
                              min="1"
                              max="86400"
                              @change="msg.offset = Math.max(Math.min(msg.offset, 86400), 1);"
                          />
                          <div class="text-center">
                            {{ $t('server.view.messenger.interval.description') }}
                            <div>
                              <i> {{ humanizeDuration(msg.interval) }}</i>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-12 sm-spacer" style="margin-top: 2rem;">
                          <b-input
                              v-model="msg.offset"
                              :readonly="msg.uuid === 'default-branding' && !isSubscriber"
                              size="lg"
                              :placeholder="$t('server.view.messenger.offset.placeholder')"
                              type="number"
                              class="form-control"
                              min="1"
                              max="86400"
                              @change="msg.offset = Math.max(Math.min(msg.offset, 86400));"
                          />
                          <div class="text-center">
                            {{ $t('server.view.messenger.offset.description') }}
                            <div v-if="msg.offset > 0">
                              <i> {{ humanizeDuration(msg.offset) }}</i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template>
                        <div class="row mt-2">
                          <div class="col-lg-12 col-sm-12">
                            <h4>{{ $t('server.view.messenger.string.label') }}</h4>
                            <hr>
                            <div class="row">
                              <div class="col">
                                <b-input
                                    v-model="msg.string"
                                    size="lg"
                                    type="text"
                                    class="form-control"
                                    :readonly="msg.uuid === 'default-branding' && !isSubscriber"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <!---------------------------------------->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-12 hide-sm">
	        <div class="" ref="floatingContainer">
	          <div class="text-center">
	            <h4 class="p-0 d-inline-block mr-2 align-middle">Module</h4>
	            <toggle-button v-model="configuration.messenger.status"
	                           :sync="true"
	                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
	                           :height="30"
	                           :width="70"
	                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
	                           style="font-size: 13px !important;"
	                           class="m-0 mb-1"
	            />
	          </div>
	          <button class="btn btn-block mt-1 hard-border save-btn"
	                  v-on:click="saveConfiguration()"
	                  :disabled="inProgress"
	                  :class="{
	                    'disabled': inProgress,
	                    'btn-success': configurationChanged === true,
	                    'btn-primary': configurationChanged === false
	                  }"
	          >
	            <half-circle-spinner
	                v-if="inProgress"
	                :animation-duration="1200"
	                :size="16"
	                class="align-middle d-inline-block"
	            />
	            <template v-if="!inProgress">
	              {{ $t('server.view.configuration.save.button') }}
	            </template>
	          </button>
	          <div class="text-center small text-muted" v-if="configurationChanged === true">
	            {{ $t('server.view.configuration.save.info') }}
	          </div>

	          <template v-if="!limit || configuration.messenger.messages.length < limit">
	            <button class="btn btn-info btn-block hard-border mt-2 mb-2" v-on:click="addElement()">
	              <i class="far fa-plus" />
	              {{ $t('server.view.messenger.button') }}
	            </button>
	          </template>
	          <template v-if="limit">
	            <div class="card text-center mb-0">
	              <div class="card-body" v-if="configuration.messenger.messages.length >= limit">
	                <h5 class="text-danger">
	                  <i class="fad fa-times-hexagon text-danger"/>
	                  {{$t('server.view.messenger.limits.reached')}}
	                </h5>
	                <span class="h5 text-danger">{{configuration.messenger.messages.length}} / {{limit}}</span>
	              </div>
	              <div class="card-body" v-else>
	                <h5>
	                  {{$t('server.view.messenger.limits.info')}}
	                </h5>
	                <span class="h5">{{configuration.messenger.messages.length}} / {{limit}}</span>
	              </div>
	            </div>
	          </template>

	          <div class="row mt-2">
	            <div class="col-lg-12">
	              <div class="card">
	                <div class="card-body">
	                  <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
	                  {{$t('server.view.configuration.placeholders.message')}}
	                  <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
	                    <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
	                  </a>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
